<template>
  <div>
    <billing v-if="isBilling"></billing>
    <div v-else style="padding: 250px 10px;text-align: center;">
      <div v-if="!is_enable">
        <div>만료된 결제입니다. 관리자에게 문의주세요.</div>
      </div>
      <div v-else>
        <div style="margin-bottom:6px">{{ errMsg }}</div>
        <div style="margin-bottom:12px">결제를 다시 시도해주세요.</div>
        <button class="button btn" @click="repay">결제 재시도</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Billing from "./oldBilling";
  import moment from 'moment';

  export default {
    name: "pg",

    components: {
      Billing
    },

    data() {
      return {
        isBilling: false,
        errMsg: '',
        // 절차 진행 여부
        is_enable: true
      }
    },

    created() {
      this.serviceCheck();
    },

    computed: {
      isMobile() {
        if(navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)) return true;
        else return false;
      }
    },
    methods: {
      getData() {
        if(this.$route.query.status==='400') {
          this.errMsg = this.$route.query.resultmsg;
          return;
        }

        if(this.isMobile) {
          this.isBilling = true;
        }
        else {
          let pg = 'https://master.launchpack.co.kr:8081';
          let query = this.$route.query;

          let url = `${pg}/inicis/pay/lpPayBill.php`;
          url += `?goodname=${query.goodname}`;
          url += `&price=${query.price}`;
          url += `&buyername=${query.name}`;
          url += `&buyertel=${query.phone}`;
          url += `&buyeremail=${query.email}`;
          location.replace(url);
        }
      },
      // 서비스 확인
      serviceCheck () {
        // 만료일이 지났을 경우는 해당 페이지가 만료되었음을 표시
        let service_id = this.$route.query.service_id
        if(service_id) {
          this.is_enable = true;
          this.$axios.get(`public/launchpack/service/${service_id}`).then(res => {
            let service = res.data;
            if(service.request_type===0) {
              let option = service.option;
              // 첫달만 할인일 경우
              if (option.is_first) {
                let expired_date = moment(service.created_time).add(option.maximum, 'day');
                let cur_date = moment();
                // 만료일이 아직 안되었을 경우
                if (cur_date < expired_date) this.is_enable = true;
                else {
                  this.is_enable = false;
                }

                // 만료일이 지났을 경우 밑에 로직 절차 진행 해야하는 경우
                if (this.$route.query.is_first === 'false') {
                  this.is_enable = true;
                }

              }
            }
            // 절차 진행이 true 일경우만 밑에 로직 절차 진행가능
            if(this.is_enable) {
              this.getData();
            }
          })
        }
      },
      repay() {
        if(this.isMobile) {
          this.isBilling = true;
        } else {
          let pg = 'https://master.launchpack.co.kr:8081';
          let query = this.$route.query;

          let url = `${pg}/inicis/pay/lpPayBill.php`;
          url += `?goodname=${query.goodname}`;
          url += `&price=${query.price}`;
          url += `&buyername=${query.name}`;
          url += `&buyertel=${query.phone}`;
          url += `&buyeremail=${query.email}`;
          location.replace(url);
        }
      }
    }

  }
</script>

<style scoped>

</style>
